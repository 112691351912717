import React, { lazy, Suspense } from 'react';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import themeSettings from './theme/ThemeSettings';
import RTL from './theme/RTL';
import INIT_STATE from 'shared/globalTheme/themeConfig';
import './App.css';
import Spinner from 'shared/components/Spinner';
function App() {
	// eslint-disable-next-line new-cap
	const theme = themeSettings();
	const [loading, setLoading] = React.useState(true);
	setTimeout(() => {
		setLoading(false);
	}, 3000);

	if (loading) {
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
			}}>
				<Spinner />;
			</div>
		)
	}

	return (
		<>
			<Suspense fallback={<Spinner />}>
				<ThemeProvider theme={theme}>
					<RTL direction={INIT_STATE.activeDir}>
						<CssBaseline />
						<BrowserRouter>
							<Routes />
						</BrowserRouter>
					</RTL>
				</ThemeProvider>
			</Suspense>
		</>
	);
}

export default App;
